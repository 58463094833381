<template>
  <b-modal header-class="ion-bg-modal-header" size="lg"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('payment.priceTitle') }} {{ $t('common.create') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <!-- Hq Info Start-->
        <div class="ion-modal-form-group-title">{{ $t('payment.hqInfo') }}</div>
        <div class="row mb-4">
          <div class="col-xl-6">
            <label class="form-label" for="price-plan-create-hq">
              <span>{{ $t('common.headquarters') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <multiselect id="price-plan-create-hq"
                         v-model="form.hqId"
                         label="text" track-by="value"
                         :options="hqOptions"
                         :placeholder="$t('alert.selectHq')"
                         selectLabel=""
                         selectedLabel=""
                         deselectLabel=""
                         :searchable="false" :allow-empty="false"
                         :class="{ 'is-invalid': submitted && $v.form.hqId.$error }"
                         @select="retrieveHqPriceEndDate" />
            <div v-if="submitted && $v.form.hqId.$error" class="invalid-feedback">
              <span v-if="!$v.form.hqId.required">{{ $t('validation.hqIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6"></div>
        </div>
        <!-- Hq Info End -->

        <!-- Price Plan Info Start-->
        <div class="ion-modal-form-group-title">{{ $t('payment.priceInfo') }}</div>
        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="price-plan-create-name">
              <span>{{ $t('payment.priceName') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="price-plan-create-name"
                          v-model="form.priceName"
                          :class="{ 'is-invalid': submitted && $v.form.priceName.$error }" />
            <div v-if="submitted && $v.form.priceName.$error" class="invalid-feedback">
              <span v-if="!$v.form.priceName.required">{{ $t('validation.priceNameIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <label class="form-label" for="price-plan-create-vat">
              <span>{{ $t('payment.tax') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <div class="row">
              <div class="col-3 text-center align-content-center">{{ $t('payment.taxRate') }}</div>
              <div class="col-6">
                <b-form-input class="form-control" type="text" id="price-plan-create-vat"
                              v-model="form.vat"
                              :class="{ 'is-invalid': submitted && $v.form.vat.$error }"
                              @change="setSellingPrice"/>
                <div v-if="submitted && $v.form.vat.$error" class="invalid-feedback">
                  <span v-if="!$v.form.vat.required">{{ $t('validation.taxRateIsRequired') }}</span>
                </div>
              </div>
              <div class="col-3 text-start align-content-center">%</div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xl-12">
            <div class="font-size-14 fw-semibold mb-2">{{ $t('payment.chargingFeeUnitPrice') }}</div>
            <table class="w-100 price-table">
              <thead>
              <tr>
                <th>{{ $t('payment.chargingSpeed') }}</th>
                <th><span>{{ $t('common.slow') }}</span><i class="uil uil-asterisk text-danger ms-1"></i></th>
                <th><span>{{ $t('common.fast') }}</span><i class="uil uil-asterisk text-danger ms-1"></i></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('payment.priceBasedOnAmount') }}</th>
                <td>
                  <div class="d-flex align-items-center p-2">
                    <b-form-input class="form-control"
                                  type="text"
                                  id="price-plan-create-slow-supply"
                                  v-model="form.slowSupplyPrice"
                                  :class="{ 'is-invalid': submitted && $v.form.slowSupplyPrice.$error }"
                                  @change="setSellingPrice" />
                    <span class="col-auto ps-2">¥/kWh</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center p-2">
                    <b-form-input class="form-control"
                                  type="text"
                                  id="price-plan-create-fast-supply"
                                  v-model="form.fastSupplyPrice"
                                  :class="{ 'is-invalid': submitted && $v.form.fastSupplyPrice.$error }"
                                  @change="setSellingPrice" />
                    <span class="col-auto ps-2">¥/kWh</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xl-12">
            <div class="font-size-14 fw-semibold mb-2">{{ $t('payment.consumerBillingRates') }}</div>
            <table class="w-100 price-table">
              <thead>
              <tr>
                <th>{{ $t('payment.chargingSpeed') }}</th>
                <th>{{ $t('common.slow') }}</th>
                <th>{{ $t('common.fast') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">{{ $t('payment.billingFee') }}</th>
                <td>
                  <div class="d-flex align-items-center p-2">
                    <b-form-input class="form-control" disabled
                                  type="text"
                                  id="price-plan-create-slow-selling"
                                  v-model="form.slowSellingPrice" />
                    <span class="col-auto ps-3">¥/kWh</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center p-2">
                    <b-form-input class="form-control" disabled
                                  type="text"
                                  id="price-plan-create-fast-selling"
                                  v-model="form.fastSellingPrice" />
                    <span class="col-auto ps-3">¥/kWh</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="font-size-14 fw-semibold mb-2">{{ $t('payment.rateApplicationPeriod') }}</div>
        <div class="mb-2 text-danger">{{ $t('payment.caution') }}</div>
        <div class="row">
          <div class="col-xl-4">
            <div class="btn-group w-100" role="group">
              <input type="radio" class="btn-check" id="displayTypeInput1" autocomplete="off"
                     v-model="periodType" value="infinite" />
              <label class="btn btn-outline-secondary" for="displayTypeInput1">{{ $t('payment.unlimited') }}</label>
              <input type="radio" class="btn-check" id="displayTypeInput2" autocomplete="off"
                     v-model="periodType" value="period" />
              <label class="btn btn-outline-secondary" for="displayTypeInput2">{{ $t('payment.setPeriod') }}</label>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="row">
              <div class="col">
                <div class="row">
                  <date-picker type="date"
                               v-model="form.startDate" class="col"
                               :show-time-panel="showTimePanel" value-type="format"
                               :disabled-date="notBeforeToday"
                               :class="{ 'is-invalid': submitted && ($v.form.startDate.$error || $v.form.endDate.$error) }"
                               @close="handleOpenChange">
                    <template v-slot:footer="{ emit }">
                      <div class="d-flex justify-content-start">
                        <button class="mx-btn mx-btn-text" @click="emit(new Date())">{{ $t('common.today') }}</button>
                      </div>
                    </template>
                  </date-picker>

                  <span class="col-auto align-content-center">~</span>

                  <date-picker type="date"
                               v-model="form.endDate" class="col"
                               :show-time-panel="showTimePanel" value-type="format"
                               :disabled-date="notBeforeStartDate"
                               :class="{ 'is-invalid': submitted && ($v.form.startDate.$error || $v.form.endDate.$error) }"
                               @change="handleDateChange"
                               @close="handleOpenChange">
                    <template v-slot:footer="{ emit }">
                      <div class="d-flex justify-content-start">
                        <button class="mx-btn mx-btn-text" @click="emit(new Date())">{{ $t('common.today') }}</button>
                      </div>
                    </template>
                  </date-picker>
                </div>
              </div>
              <div v-if="submitted && ($v.form.startDate.$error || $v.form.endDate.$error)" class="text-danger">
                <span v-if="!$v.form.startDate.required || !$v.form.endDate.required">{{ $t('validation.periodIsRequired') }}</span>
                <span v-else-if="!$v.form.endDate.isAfterOrEqualStartDate">{{ $t('validation.periodEndDateErr') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  components: { Multiselect },
  data() {
    return {
      visible: false,
      submitted: false,
      hqOptions: [],
      periodType: 'infinite',
      form: {
        priceName: null,
        slowSupplyPrice: null,
        fastSupplyPrice: null,
        vat: null,
        slowSellingPrice: null,
        fastSellingPrice: null,
        startDate: null,
        endDate: null,
        hqId: null
      },
      showTimePanel: false,
    }
  },
  validations() {
    const isAfterStartDate = (value) => {
      if (!value || !this.form.startDate) return true;
      return new Date(value) >= new Date(this.form.startDate);
    };
    return {
      form: {
        hqId: { required },
        priceName: { required },
        slowSupplyPrice: { required },
        fastSupplyPrice: { required },
        vat: { required },
        startDate: { required },
        endDate: { required, isAfterStartDate },
      }
    }
  },
  watch: {
    periodType() {
      const vm = this;
      vm.initPeriod();
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getHqOptions();
      vm.visible = true;
      vm.initPeriod();
    },
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err.response)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        priceName: null,
        slowSupplyPrice: null,
        fastSupplyPrice: null,
        vat: null,
        slowSellingPrice: null,
        fastSellingPrice: null,
        startDate: null,
        endDate: null,
        hqId: null
      };
      vm.hqOptions = [];
      vm.periodType = 'infinite';
      vm.submitted = false;

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmCreate'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/PaymentUnitPrice/create';

      vm.form.startDate = this.$moment(vm.form.startDate).format('YYYY-MM-DD 00:00:00');
      vm.form.endDate = this.$moment(vm.form.endDate).format('YYYY-MM-DD 23:59:59');

      let _form = Object.assign({}, vm.form);

      if (!this.$_utils.isEmpty(vm.form.hqId)) {
        _form.hqId= vm.form.hqId.value;
      } else {
        delete _form.hqId;
      }

      http.post(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.created'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Price Plan Create Error :: ', err.response)
        if (err.response.data.message === 'Invalid Period') {
          Swal.fire({
            icon: 'error',
            title: i18n.t('payment.warningPeriodOverlapping'),
            text: i18n.t('payment.resetPeriod1'),
            confirmButtonText: i18n.t('btn.close')
          });
        }
      });
    },
    setSellingPrice() {
      const vm = this;

      if (vm.form.vat !== null) {
        if (vm.form.slowSupplyPrice !== null) {
          vm.form.slowSellingPrice = (vm.form.slowSupplyPrice * ( vm.form.vat * 0.01 + 1 )).toFixed(0);
        }
        if (vm.form.fastSupplyPrice !== null) {
          vm.form.fastSellingPrice = (vm.form.fastSupplyPrice * ( vm.form.vat * 0.01 + 1 )).toFixed(0);
        }
      }
    },
    initPeriod() {
      const vm = this;

      if (vm.periodType === 'infinite') {
        if (vm.form.startDate === null) {
          vm.form.startDate = this.$moment().format('YYYY-MM-DD 00:00:00');
        }
        vm.form.endDate = '9999-12-31 23:59:59';
      } else {
        if (vm.form.startDate === null) {
          vm.form.startDate = this.$moment().format('YYYY-MM-DD 00:00:00');
        }
        if (vm.form.endDate === null) {
          vm.form.endDate = this.$moment().add(1, 'month').format('YYYY-MM-DD 23:59:59');
        }
      }
    },
    handleDateChange(val) {
      const vm = this;

      if (val === '9999-12-31') {
        vm.periodType = 'infinite';
      } else {
        vm.periodType = 'period';
      }
    },
    notBeforeToday(date) {
      return moment(date).isBefore(moment(), 'day');
    },
    notBeforeStartDate(date) {
      const vm = this;

      if (vm.form.startDate === null) {
        return moment(date).isBefore(moment(), 'day');
      } else {
        return moment(date).isBefore(moment(vm.form.startDate), 'day');
      }
    },
    handleOpenChange() {
      const vm = this;
      vm.showTimePanel = false;
    },
    async retrieveHqPriceEndDate(val) {
      const vm = this;
      const selectedHqId = val.value;

      const hqPriceData = await vm.retrieveHqPrice(selectedHqId);

      if (hqPriceData) {
        const isInFinitePricePlan = hqPriceData.endDate === '9999-12-31 23:59:59';
        if (isInFinitePricePlan) {
          await Swal.fire({
            icon: 'error',
            title: i18n.t('payment.rateCannotBeSet'),
            text: i18n.t('payment.resetPeriod2'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.form.hqId = null;
        } else {
          vm.form.startDate = this.$moment(hqPriceData.endDate).add(1, 'day').format('YYYY-MM-DD 00:00:00');
        }
      } else {
        vm.form.startDate = this.$moment().format('YYYY-MM-DD 00:00:00');
      }
    },
     retrieveHqPrice(selectedHqId) {
      const _url = `/onmapi/view/Hq/retrieve/${selectedHqId}`;

      return http.get(_url).then((res) => {

        return res.data.item.paymentUnitPrice;

      }).catch((err) => {
        console.error('Hq retrieve Error !!', err)
        throw err;
      });
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
::v-deep {
  .price-table {
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      width: 33%;
      border: 1px solid #e2e5e8;
      font-weight: normal;
    }
    th {
      font-weight: bold;
      background: #f2f6f9;
      text-align: center;
      padding: 10px 0;
    }
  }
}
</style>
